<template>
  <div
    :style="[textAlignCss, textColorCss]"
    class="text-block"
    :class="textSize"
  >
    <div
      ref="content"
      v-html="textContent"
      class="content"
      :class="isCollapsed && readMore && !isTiny && 'collapse'"
    />
    <button
      v-if="readMore && !isTiny"
      @click="toggleCollapse"
    >
      {{ isCollapsed ? $t("TextBlock_ReadMore") : $t("TextBlock_ReadLess") }}
    </button>
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode.js'

export default {
  name: 'TextBlock',
  mixins: [ColorHexCode],
  props: {
    text: {
      type: Object,
      default: () => ({}),
    },
    textInput: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '#000000',
    },
    textColorHex: {
      type: String,
      required: false,
      default: '',
    },
    readMore: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    textSize: {
      type: String,
      default: 'p--small',
    },
  },
  data () {
    return {
      isCollapsed: true,
      isTiny: false,
    }
  },
  computed: {
    textColorCss () {
      return { color: this.color(this.textColor, this.textColorHex) }
    },
    textAlignCss () {
      return { textAlign: this.textAlign }
    },
    textContent () {
      const regex = /\/site/g

      return this.$md.render(typeof this.textInput === 'string' ? this.textInput : '')?.replace(regex, '') || this.text?.html
    },
  },
  mounted () {
    if (this.$refs?.content?.clientHeight < 130) {
      this.isTiny = true
    }
  },
  methods: {
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.text-block {
  padding: 1.6rem 1.6rem 1.6rem 1.6rem;
  &.p--mini {
    @include p--mini;
  }
  &.p--small {
    @include p--small;
  }
  &.p--medium {
    @include p--medium;
  }
  &.p--large {
    @include p--large;
  }

  button {
    background: none;
    border: none;
    color: black;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0;
    text-underline-offset: 4px;
    line-height: 20px;
    letter-spacing: 6%;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 8px;
  }

  .content {

    &.collapse {
      overflow: hidden;
      position: relative;
      max-height: 130px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 90px;
        background: linear-gradient(0deg, #f9f9f9, transparent);
      }
    }
  }
  &::v-deep {
    ul,
    ol {
      @include list('—');
    }
    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .text-block {
    font-size: 1.4rem;
  }
}
</style>
